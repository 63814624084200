import styled from 'styles/styled';

export const WrapScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 350px);
  @media (max-width: 720px) {
    padding-right: 20px;
  }
`;

export const SubTitle = styled.p`
  color: #EFEFEF;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  width: 900px;
  max-width: 90%;
  margin: 0 auto 32px auto;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }
`;

export const LargeTitle = styled.h3`
  color: #EFEFEF;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  width: 900px;
  font-weight: 500;
  max-width: 90%;
  margin: 10px auto 32px auto;
  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }
`;

export const Title = styled.h5`
  color: #EFEFEF;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Text = styled.p`
  color: #EFEFEF;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 32px;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  a {
    color: #52D381;
  }
`;
